export default function Input(props) {
    return (
        <div className='flex flex-col w-full'>
            <div className={`box-hover shadow-sm outline outline-transparent relative border rounded focus-within:border-blue-500 ${props.error && 'border-2 border-red-500'}`}>
                <input 
                    className={`block ${props.size === 's' ? 'p-2' : 'p-4'} w-full text-lg appearance-none focus:outline-none bg-transparent ${props.error && 'outline-none border-none'} ${props.disabled ? 'bg-gray-50 cursor-not-allowed': ''}` } 
                    placeholder=" " {...props} 
                />
                <label 
                    htmlFor={props.name} 
                    className={`absolute top-0 text-md text-gray-600 bg-white ${props.size === 's' ? 'p-2' : 'p-4'} pointer-events-none duration-300 origin-0`}
                >
                    {props.label}
                </label>
            </div>
            <div className="flex text-xs text-red-500">
                {props.error}
            </div>
        </div>
    )
}
