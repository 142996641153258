import { FiBox, FiX } from 'react-icons/fi'
import Router from 'next/router'
import useSWR from 'swr'
import dayjs from 'dayjs'
import fetcher from '@byma/shared/helpers/fetcher'
import Loader from '@byma/shared/components/Loader'
import Input from '@byma/shared/components/Input'
import React from 'react'

function EventItem({event, active=false, hideCheckbox=false}) {
    return (
        <div className={`flex items-center cursor-pointer box box-hover ${active && 'box-active'} gap-3`}>
            {!hideCheckbox && (
                <input type="checkbox" checked={active} readOnly />
            )}
            { event?.pictures?.photos[0] ? (
                <img src={event.pictures.photos[0]} alt={event.name} className="rounded-full w-12 h-12 object-cover" />
            ) : (
                <div className="w-12 h-12 rounded-full bg-gray-400 overflow-hidden" />
            ) }
            <div className="">
                <p className="text-md font-bold">{event.name}</p>
                <p className="text-sm">{dayjs(event.date).format('DD/MM/YYYY [às] hh:mm')}</p>
            </div>
        </div>
    )
}

function toQueryString(obj, forceKey=null) {
    if (!obj) return ''
    return Object.keys(obj).map(function (key) {
        if (typeof obj[key] === 'object') {
            return toQueryString(obj[key], key)
        }
        return (forceKey || key) + '=' + obj[key]
    }).join('&');
}

export default function EventFilter({ token, filteredEvents=[] }) {
    const [eventFilterOpen, setEventFilterOpen] = React.useState(false);
    const [selectedEvents, setSelectedEvents] = React.useState([])

    const [searchQuery, setSearchQuery] = React.useState('')

    const { data: parties, error } = useSWR(token ? [`/api/parties/getAll?search=${searchQuery}&perPage=30`, null, token] : null, fetcher)
    const { data: fetchedSelectedEvents } = useSWR(selectedEvents.length && token ? [`/api/parties/getAll?${toQueryString({partyIds: selectedEvents, perPage: 30})}`, null, token] : null, fetcher)

    React.useEffect(() => {
        if (filteredEvents) {
            setSelectedEvents(typeof filteredEvents === 'string' ? [filteredEvents] : filteredEvents)
        }
    }, [filteredEvents])

    function handleSelect(party) {
        if (selectedEvents.indexOf(party._id) >= 0) {
            setSelectedEvents(selectedEvents.filter(e => e !== party._id))
        } else {
            setSelectedEvents([...selectedEvents, party._id])
        }
    }

    function handleFilter() {
        let url = new URL(window.location.href)
        let searchParams = url.searchParams
        searchParams.delete('p')
        selectedEvents.forEach(event => searchParams.append('p', event))
        url.search = searchParams.toString()
        Router.push(url.toString())
        setEventFilterOpen(false)
    }

    async function handleSearch(e) {
        e.preventDefault()
        setSearchQuery(e.currentTarget.search.value)
    }

    return (
        <div className="">
            <button className="flex justify-between items-center cursor-pointer rounded shadow px-2 py-1 text-sm hover:shadow-md whitespace-nowrap focus:shadow-sm" onClick={() => setEventFilterOpen(!eventFilterOpen)}>
                <FiBox className="mr-1" />
                {selectedEvents.length > 0 ? (
                    <p>
                        {selectedEvents.length} evento{selectedEvents.length > 1 ? 's' : ''}
                    </p>
                ) : (
                    <p>
                        Todos os eventos
                    </p>
                )}
            </button>
            <div className={`fixed w-full h-full inset-0 transition-opacity duration-100 ${eventFilterOpen ? 'z-50 opacity-100' : '-z-1 opacity-0'}`} onClick={() => setEventFilterOpen(false)}>
                <div class="absolute w-full h-full bg-gray-900 opacity-50" />
                <div class="modal-content absolute bg-white w-full md:w-11/12 md:max-w-md md:mx-auto md:rounded md:shadow-lg z-50 md:h-3/4 h-full" onClick={(e) => e.stopPropagation()}>
                    <div className="flex flex-col justify-between p-3 space-y-3 h-full">
                        <div className="">
                            <div className="flex justify-between items-center mb-3">
                                <div className="">
                                    <p className="font-bold text-xl">Filtrar por evento</p>
                                </div>
                                <div className="cursor-pointer" onClick={() => setEventFilterOpen(false)}>
                                    <FiX size={24}/>
                                </div>
                            </div>
                            <form onSubmit={handleSearch}>
                                <Input size="s" name="search" type="text" label={(
                                    <div className="flex space-x-2 items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                        </svg>
                                        <p>Buscar eventos...</p>
                                    </div>
                                )} />
                            </form>
                        </div>
                        <div className="overflow-y-auto h-full box">
                            <div className="flex flex-col">
                                { parties ? parties.result.map(party => (
                                    <div onClick={() => handleSelect(party)}>
                                        <EventItem event={party} active={selectedEvents.indexOf(party._id) >=0} />
                                    </div>
                                )) : <Loader />}
                            </div>
                        </div>
                        {selectedEvents.length > 0 && (
                            <>
                                <p className="font-bold text-md">Eventos selecionados ({selectedEvents.length})</p>
                                <div className="h-48 overflow-y-auto box">
                                    {fetchedSelectedEvents?.result?.map(party => (
                                        <div onClick={() => handleSelect(party)}>
                                            <EventItem event={party} hideCheckbox={true} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <div className="flex space-x-2">
                            {selectedEvents.length === 0 ? (
                                <button className="btn btn-primary w-full" onClick={handleFilter}>Remover filtro de eventos</button>
                            ) : (
                                <>
                                    <button className="btn btn-primary w-full" onClick={handleFilter}>Filtrar {selectedEvents.length} evento{selectedEvents.length > 1 ? 's' : ''}</button>
                                    <button className="btn btn-white w-full" onClick={() => setSelectedEvents([])}>Limpar sele&ccedil;&atilde;o</button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
